var about = (function(){
  'use strict'
  var controller = new ScrollMagic.Controller();

  var _counterNumber = function(selector) {
    $(selector).each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
  }

  var triggerCounterNumber = function(selector) {
    var scene = new ScrollMagic.Scene({triggerElement: "#youTube"})
            // trigger animation by adding a css class
            .on('start', function(){
              if(!$(selector).hasClass('done')) {
                _counterNumber(selector)
                $(selector).addClass('done')
              }
              
            })
            .addTo(controller);
  }

  return {
    init: function(settings) {
      if(!!settings) {
        triggerCounterNumber(settings.selectorCounterNumber)
      }
      
    }
  }
})();