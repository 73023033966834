var news = (function(){

  'use strict'

  var $body = $('#body')
  var titleNewsClass = '.news-square__title'
  var shareMobile = '.share-mobile, .share-news-mobile-close'
  var $ctShareMobile = $('.ct-share-news-mobile')

  var animateHoverNewsTitle = function() {
    $(document).on('mouseover', titleNewsClass, _toggleAnimateNewsTitle)
    $(document).on('mouseleave', titleNewsClass, _toggleAnimateNewsTitle)
  }

  var animateShareMobile = function(settings) {

    var DEFAULTS = {
      slug: 'news'
    }

    settings = $.extend(DEFAULTS, settings);

    $(document).on('click', shareMobile, settings, _toggleShareMobile)
  }

  var _toggleAnimateNewsTitle = function() {
    var self = this
    var $parentNewsSquare = $(self).closest('.news-square')
    var isHover = $parentNewsSquare.hasClass('news-square-hover')

    if(isHover) {
      $parentNewsSquare.removeClass('news-square-hover')
      return $parentNewsSquare
    } 
    else {
      $parentNewsSquare.addClass('news-square-hover')
      return $parentNewsSquare
    }

    return false

  }

  var _toggleShareMobile = function(e) {
    console.log(e.data);
    var $this     = $(this),
        isActive  = $ctShareMobile.hasClass('active'),
        dataImage,
        dataTitle,
        dataUrl,
        shares = [{
            '$el': $('#shareOnFb')
          }, {
            '$el': $('#shareOnTwitter')
          }, {
            '$el': $('#shareOnCopyLink')
        }];

    if(e) e.preventDefault()
    
    // if in video page
    if(e.data.slug === 'video') {
      dataImage = $this.attr('data-share-image');
      dataTitle = $this.attr('data-share-title');
      dataUrl   = $this.attr('data-share-url');

      // inject data all element share
      $.each(shares, function(i, share){
        share.$el.attr('data-share-image', dataImage);
        share.$el.attr('data-share-title', dataTitle);
        share.$el.attr('data-share-url', dataUrl);
       });

    }

    if(isActive) {
      $body.removeClass('share-mobile-bg-active')
      $ctShareMobile.removeClass('active')
    } else {
      $body.addClass('share-mobile-bg-active')
      $ctShareMobile.addClass('active')
    }

  }

  var _toggleStickySharePosition = function() {
    var $window = $(window),
        scrollTop,
        $stickyShare = $('.sticky-share');

    $window.on('scroll', watchScrolling);

    $window.on('resize', watchScrolling);

    function watchScrolling() {
      if($window.width() > 768 && $window.height() < 768) {
        scrollTop = $window.scrollTop();
        
        if(scrollTop < 270) {
          $stickyShare.removeClass('stop');
        }

        if(scrollTop > 270) {
          $stickyShare.addClass('stop');
        }
      }
    }
  }

  return {
    init: function() {
      animateHoverNewsTitle()
    },
    initToggleShareMobile: function(settings) {
      animateShareMobile(settings)
    },
    toggleStickySharePosition: _toggleStickySharePosition
  }
})();