var contact = (function(){
  
  'use strict'

   var $container = $('.contact-response')

  var initChosen = function() {
    $(".chosen-select").chosen({
      disable_search_threshold: 10,
      placeholder_text_single: 'Choose option'
    });
    console.log('chosen selector .chosen-select')
  }

  var _addFormError = function(targetSelector, settings) {
    var openEl = '<p>',
        closeEl = '</p>'
    var DEFAULTS = {
      message: 'this field required.'
    }

    settings = $.extend(DEFAULTS, settings) 

    
    $(targetSelector)
        .addClass('form-error')
        .append(openEl + settings.message + closeEl);

  }

  var _deleteFormError = function(targetSelector) {
    $(targetSelector)
        .removeClass('form-error')
    $(targetSelector + ' p').remove()
  }

  var _setFormMessage = function(settings) {
   
    var isExist = $container.find('.alert').length === 0
    var $el;

    var DEFAULTS = {
      priority: 'success',
      title: 'MESSAGE SENT',
      message: 'Thank you. We will contact you back soon!'
    }

    settings = $.extend(DEFAULTS, settings) 

    var $alert      = $('<div class="alert alert-' + settings.priority + ' mb_46"></div>')
    var $alertImg   = $('<img src="/images/global/' + settings.priority + '-icon.png" class="alert__icon" />')
    var $alertBody  = $('<div class="alert__body text-left"></div>')
    var $alertTitle = $('<h4 class="alert__title barlow semibold ls_0 lh_22 size_18 mb_3">' + settings.title + '</h4>')     
    var $alertMsg   = $('<p class="alert__message barlow medium size_16 ls_0 lh_19">' + settings.message + '</p>')
    
    if(isExist) {
      $el = $alertBody
              .append($alertTitle)
              .append($alertMsg)
      $el = $alert.append($alertImg)
                  .append($el)

      $container.append($el)
      return $el
    }
        
  }

  var _clearFormMessage = function() {
    $container.empty()
  }

  return {
    init: function() {
      initChosen()
    },
    addFormError: function(targetSelector, settings){
     return  _addFormError(targetSelector, settings)
    },
    deleteFormError: function(targetSelector) {
      return _deleteFormError(targetSelector)
    },
    setFormMessage: function(settings) {
      return _setFormMessage(settings)
    },
    clearFormMessage: function() {
      return _clearFormMessage()
    }
  }
})();